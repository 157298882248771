import { OperatingHour } from "@/redux/slices/locationSlice";
import { Country, State } from "country-state-city";
import { TeamMemberRolesType } from "@/types/team-member";
import { DateTime } from "luxon";

export const API_URI = import.meta.env.VITE_APP_API_URI;

export const BOOKING_URI = "room_booking/partner-company";
export const BOOKING_MANAGEMENT_URI = "room_booking/booking";
export const ADMIN_URI = "room_booking/business";
export const ROOM_BOOKING_URI = "room_booking";
export const BUSINESS_URI = "my-business";

export const DEFAULT_THEME = "#053969";


export const WAITLIST_ENVIRONMENT_LINK =
	(import.meta.env.VITE_ENVIRONMENT as "dev" | "staging" | "production") ===
	"dev"
		? "https://admin.dev.migranium.com"
		: import.meta.env.VITE_ENVIRONMENT === "staging"
			? "https://admin.staging.migranium.com"
			: import.meta.env.VITE_ENVIRONMENT === "production"
				? "https://admin.migranium.com"
				: "https://admin.dev.migranium.com";

export const SPACES_ENVIRONMENT_LINK =
	(import.meta.env.VITE_ENVIRONMENT as "dev" | "staging" | "production") ===
	"dev"
		? "https://spaces.dev.migranium.com"
		: import.meta.env.VITE_ENVIRONMENT === "staging"
			? "https://spaces.staging.migranium.com,"
			: import.meta.env.VITE_ENVIRONMENT === "production"
				? "https://spaces.migranium.com"
				: "https://spaces.dev.migranium.com";

export const LANDING_ENVIRONMENT_LINK =
	(import.meta.env.VITE_ENVIRONMENT as
		| "dev"
		| "staging"
		| "production"
		| "local") === "dev"
		? "https://dev.migranium.com"
		: import.meta.env.VITE_ENVIRONMENT === "staging"
			? "https://staging.migranium.com"
			: import.meta.env.VITE_ENVIRONMENT === "production"
				? "https://migranium.com"
				: "https://dev.migranium.com";

export const RepeatTimeOptions = [
	{ label: "Daily", value: "daily" },
	{ label: "Weekly", value: "weekly" },
	{ label: "Monthly", value: "monthly" },
	{ label: "Yearly", value: "yearly" },
	{ label: "Does not repeat", value: "does not repeat" },
];

export const months = [
	{ label: "January", value: "1" },
	{ label: "February", value: "2" },
	{ label: "March", value: "3" },
	{ label: "April", value: "4" },
	{ label: "May", value: "5" },
	{ label: "June", value: "6" },
	{ label: "July", value: "7" },
	{ label: "August", value: "8" },
	{ label: "September", value: "9" },
	{ label: "October", value: "10" },
	{ label: "November", value: "11" },
	{ label: "December", value: "12" },
];

export const daysOfWeek = [
	{ label: "Monday", value: "2" },
	{ label: "Tuesday", value: "3" },
	{ label: "Wednesday", value: "4" },
	{ label: "Thursday", value: "5" },
	{ label: "Friday", value: "6" },
	{ label: "Saturday", value: "7" },
	{ label: "Sunday", value: "1" },
];

export const weekInMonthOptions = [
	{ label: "First", value: "1" },
	{ label: "Second", value: "2" },
	{ label: "Third", value: "3" },
	{ label: "Fourth", value: "4" },
];

export const AdminUserRoles = [
	{ label: "", value: "" },
	{ label: "", value: "" },
	{ label: "", value: "" },
];

export const defaultTimeSlots: OperatingHour[] = [
	{
		day: "Monday",
		day_value: 1,
		is_active: 1,
		time_slots: [
			{
				start_time: "09:00:00",
				end_time: "17:00:00",
			},
		],
	},
	{
		day: "Tuesday",
		day_value: 2,
		is_active: 1,
		time_slots: [
			{
				start_time: "09:00:00",
				end_time: "17:00:00",
			},
		],
	},
	{
		day: "Wednesday",
		day_value: 3,
		is_active: 1,
		time_slots: [
			{
				start_time: "09:00:00",
				end_time: "17:00:00",
			},
		],
	},
	{
		day: "Thursday",
		day_value: 4,
		is_active: 1,
		time_slots: [
			{
				start_time: "09:00:00",
				end_time: "17:00:00",
			},
		],
	},
	{
		day: "Friday",
		day_value: 5,
		is_active: 1,
		time_slots: [
			{
				start_time: "09:00:00",
				end_time: "17:00:00",
			},
		],
	},
	{
		day: "Saturday",
		day_value: 6,
		is_active: 0,
		time_slots: [
			{
				start_time: "09:00:00",
				end_time: "17:00:00",
			},
		],
	},
	{
		day: "Sunday",
		day_value: 0,
		is_active: 0,
		time_slots: [
			{
				start_time: "09:00:00",
				end_time: "17:00:00",
			},
		],
	},
];

export const durationOptions = [
	{ label: "15 minutes", value: 15 },
	{ label: "30 minutes", value: 30 },
	{ label: "45 minutes", value: 45 },
	{ label: "1 hour", value: 60 },
	{ label: "2 hours", value: 120 },
	{ label: "3 hours", value: 180 },
	// { label: "6 hours", value: 360 }, // Corrected value for 6 hours
	// { label: "12 hours", value: 720 }, // Corrected value for 12 hours
	// { label: "24 hours", value: 1440 }, // Corrected value for 24 hours
];

export const countryCodes = Array.from({ length: 249 }, (_, i) => {
	return {
		label: "+" + (i + 1),
		value: "+" + (i + 1),
	};
});

const initialCountryOptions = Object.values(Country.getAllCountries())
	.map((country) => ({
		value: country.isoCode,
		label: country.name,
	}))
	.filter((country) => country.value !== "CA" && country.value !== "US");

export const countryOptions = [
	{ value: "CA", label: "Canada" },
	{ value: "US", label: "United States" },
	...initialCountryOptions,
];

export const statesOptions = (country: string) =>
	country
		? Object.values(State.getStatesOfCountry(country)).map((state) => ({
				label: state.name,
				value: state.isoCode,
			}))
		: [];

export const findCountry = (current: string = "") =>
	countryOptions.find((country) => country.value === current)?.label || "";

export const findState = (current: string = "", country: string = "") =>
	statesOptions(country).find((state) => state.value === current)?.label ||
	"";

export const roleOptions: {
	label: string;
	value: TeamMemberRolesType;
	description: string;
}[] = [
	{
		label: "Super Admin",
		value: "SUPER_ADMIN",
		description:
			"Ultimate access for all system aspects, user management, system settings, data oversight, and full platform operation across all locations.",
	},
	{
		label: "Location Manager",
		value: "LOCATION_MANAGER",
		description:
			"Manages appointment schedules, patient flow, and resource allocation for a specific clinic or hospital location.",
	},
	{
		label: "Space Manager",
		value: "ROOM_MANAGER",
		description:
			"Oversees reservations, updates space schedules, and coordinates healthcare provider-patient interactions for assigned rooms.",
	},
	{
		label: "Partner Manager",
		value: "PARTNER_MANAGER",
		description:
			"Creates and oversees reservations, coordinates interactions for assigned partner(s).",
	},
	{
		label: "Team Member",
		value: "TEAM_MEMBER",
		description:
			"Can view all reservations and partner activity at selected location(s).",
	},
];

export const today = DateTime.local();

export const defaultDay = today.day.toString();

// Get today's month (e.g., "January")
export const defaultMonth = {
	label: today.monthLong,
	value: today.month.toString(),
};

// Get today's weekday (e.g., "Sunday")
export const defaultWeekDay = {
	label: today.weekdayLong,
	value: today.weekday.toString(),
};

// Get today's week of the month
export const defaultWeekOfMonth = Math.ceil(today.day / 7).toString();

// Upload formats
export const allowedUploadFormats = [
	"image/jpeg",
	"image/jpg",
	"image/png",
	"image/gif",
	"image/svg+xml",
];

// export const
