import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { API_URI } from "./constants";
import { jwtDecode } from "jwt-decode";
import { getCookie, setCookie } from "./cookie";

interface JWTPayload {
	exp: number;
}

const TOKEN_COOKIE_NAME = "TOKEN_COOKIE";
const TOKEN_EXPIRY_DAYS = 0.41;

const isTokenExpired = (token: string) => {
	try {
		const { exp } = jwtDecode<JWTPayload>(token);
		return exp * 1000 < Date.now();
	} catch (e) {
		return true;
	}
};

// Promise to prevent multiple simultaneous refresh requests
let refreshTokenPromise: Promise<void> | null = null;

const refreshToken = async (): Promise<void> => {
	const accessToken = getCookie(TOKEN_COOKIE_NAME);

	// Do not run function if access token is expired
	if (!accessToken || !isTokenExpired(accessToken)) return;
	if (!refreshTokenPromise) {
		refreshTokenPromise = (async () => {
			try {
				const { data: response, status } = await axios.get(
					`${API_URI}/refresh`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
						},
					}
				);

				if (status === 200 && response) {
					setCookie(
						TOKEN_COOKIE_NAME,
						response.token,
						TOKEN_EXPIRY_DAYS
					);
				}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					console.error("Error refreshing token:", error.response);
				}
				if ((error as AxiosError)?.response?.status === 401) {
					window.location.href = "/login";
				}
			} finally {
				refreshTokenPromise = null;
			}
		})();
	}

	await refreshTokenPromise;
};

const axiosInstance = axios.create({
	baseURL: `${API_URI}`,
	headers: {
		"Content-Type": "application/json",
	},
});

axiosInstance.interceptors.request.use(
	(config: InternalAxiosRequestConfig) => {
		// this covers what were done in the redux slices
		if (config.url !== `${API_URI}/login`) {
			config.headers.Authorization = `Bearer ${getCookie(TOKEN_COOKIE_NAME)}`;
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	(response) => {
		return response.data;
	},
	async (error) => {
		const originalRequest = error.config;
		console.log(error.response?.status);
		if (
			error.response?.status == 401 &&
			error.response?.data?.message == "Unauthenticated." &&
			!originalRequest._retry
		) {
			originalRequest._retry = true;
			await refreshToken();

			const newAccessToken = getCookie(TOKEN_COOKIE_NAME);
			if (newAccessToken) {
				originalRequest.headers["Authorization"] =
					`Bearer ${newAccessToken}`;
			}

			return axiosInstance(originalRequest);
		}
		return Promise.reject(error);
	}
);

export default axiosInstance;
