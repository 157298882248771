import { AuthContext } from "@/hooks/auth";
import { useAppSelector, useAppDispatch } from "@/hooks/hooks";
import { logOut, loginSuccess } from "@/redux/slices/userSlice";
import { persistor } from "@/redux/store";
import { setCookie } from "@/services/cookie";
import LocalStorage from "@/services/storage";
import { IUser, IUserPartner } from "@/types/user";
import { ReactNode, useEffect } from "react";

const storage = new LocalStorage();

export interface AuthData {
	user: IUserPartner | IUser | null;
	data: IUserPartner | IUser;
	token: string;
	expires_in: number;
	status: string;
}

const AuthProvider = ({ children }: { children: ReactNode }) => {
	// Change user selector from store due to store issues with persist
	const { user } = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();

	useEffect(() => {
		const storedAuthToken = localStorage.getItem("TOKEN_COOKIE");
		// const storedUser = JSON.parse(localStorage.getItem("MG:USER") || "{}");
		const storedUser: any = storage.getItem("MG:USER");

		if (storedAuthToken) {
			dispatch(loginSuccess(storedUser));
		}
	}, [dispatch]);

	const setAuthUser = (data: AuthData) => {
		if ((data && data.user) || data.data) {
			const user = data.user ?? data.data;
			if (data.token) {
				setCookie("TOKEN_COOKIE", data.token, 0.41);
				storage.setItem("TOKEN_COOKIE", data.token);
			}
			storage.setItem("MG:USER", user);
			dispatch(loginSuccess(user));

			//Update to PARTNER_SUPER_ADMIN & PARTNER_ADMIN & BUSINESS_ADMIN
			storage.setItem(
				"ACCOUNT_COOKIE",
				user.role === "BUSINESS_ADMIN" ? "admin" : "guest"
			);
			//change to constant later
			if (user.role == "PARTENER_SUPER_ADMIN") {
				storage.setItem("MG:COMPANYID", user?.company.id);
				storage.setItem("MG:BUSINESSID", user?.business.id);
				storage.setItem("MG:USERID", user?.id);
			} else if (user.role == "PARTNER_ADMIN") {
				// console.log(user);
				storage.setItem("MG:USERID", user?.id);
				storage.setItem("MG:COMPANYID", user?.company_id);
				// } else if (user.role === "BUSINESS_ADMIN") {
			} else {
				storage.setItem("ID", user.business_id);
			}
		} else {
			console.error("Invalid data structure for setAuthUser");
		}
	};

	const logout = () => {
		localStorage.clear();
		dispatch(logOut());
		storage.removeItem("TOKEN_COOKIE");
		storage.removeItem("ACCOUNT_COOKIE");
		storage.removeItem("ID");
		persistor.pause();
		persistor.flush().then(() => {
			return persistor.purge();
		});
		// notifyWithIcon('success', 'user logged out!');
	};

	return (
		<AuthContext.Provider value={{ user, setAuthUser, logout }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
